import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import RegistrationStep1 from './panels/step1';
import RegistrationStep2 from './panels/step2';
import RegistrationStep3 from './panels/step3';
import RegistrationStep3a from './panels/step3a'; //vragenlijsten tbv RCT
import RegistrationStep4 from './panels/step4';
import RegistrationStep5 from './panels/step5';
import { registrationTextObject } from '../registrationtexts/registrationTextObjects';
import apiCall from '../api';
import { setUiTranslation } from '../../actions';

const Registration = ({ landingPage, setLandingPage }) => {

  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const [registrationTexts, setRegistrationTexts] = useState(registrationTextObject)

  const [registrationData, setRegistrationData] = useState({
    step: 1,
    coachesAvailable: true,
    language_id: 1, //nl
    qualtrics_id: '',
    intervention_id: 0,
    interventionImage: '',
    agree: false,
    followUp: false,
    suicide: false, 
    email: '',
    coachChosen: 0
  })

  function updateRegistrationData(registrationDataObject) {
    setRegistrationData(registrationDataObject)
    //base64 encoded in cookie zetten voor reload
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + 1000*3600*24;
    now.setTime(expireTime);
    setCookie('registration', window.btoa(JSON.stringify(registrationDataObject)), { path: '/registration', expires: now });
  }

  useEffect(() => {

    //registratieteksten ophalen
    apiCall({
      action: "get_registration_texts",
      data: {}
    }).then(resp => {
      setRegistrationTexts(resp.content);
    });

    //i.g.v. reload evt registrationData ophalen uit cookie
    if (cookies.hasOwnProperty("registration")) {
      //hier geen updateRegistrationData want dan gaan we dezelfde data opnieuw in cookie vastleggen
      setRegistrationData(JSON.parse(window.atob(cookies.registration)))
    }

    //qualtrics parameter in url?
    if (typeof location.search !== 'undefined' && location.search.substring(0,4) === '?id=') {
      let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
      registrationDataTemp.qualtrics_id = location.search.substr(4)
      updateRegistrationData(registrationDataTemp)
      //qualtrics_id vastleggen in database...
      apiCall({
        action: "register_qualtrics_id",
        data: {
          qualtrics_id: registrationDataTemp.qualtrics_id
        }
      })
    }

  }, [])

  useEffect(() => {
    getUITranslations(registrationData.language_id);
  }, [registrationData.language_id])

  useEffect(() => {
    if (landingPage === 'en') {
        let newRegistrationData = {...registrationData}
        newRegistrationData.language_id = 2
        setRegistrationData(newRegistrationData)
    }
  }, [landingPage])

  const changeLanguage = (language_id) => {
    console.log('changeLanguage', language_id)
    let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
    registrationDataTemp.language_id = language_id
    updateRegistrationData(registrationDataTemp)

    /* if (parseInt(language_id) === 1) {
        history.push('/registration/nl/')
    } else {
        history.push('/registration/en/')
    } */
  }

  function getUITranslations(language_id) {

    if (parseInt(language_id) === 1) {
      dispatch(setUiTranslation(1,[]));
    } else {
      //getTranslation
      apiCall({
        action: "get_ui_translation_strings_by_language",
        data: {
          language_id: language_id
        }
      }).then(resp => {
        dispatch(setUiTranslation(language_id, resp.translation));
      })
    }

  }

  const Step = ({ step }) => {

    switch (step) {

      case 1:
        //keuze interventie + evt registratie qualtrics id in tabel qualtrics
        return <RegistrationStep1 registrationTexts={registrationTexts} changeLanguage={changeLanguage} registrationData={registrationData} setRegistrationData={updateRegistrationData} landingPage={landingPage} />
      case 2:
        //detailinformatie programma
        return <RegistrationStep2  registrationTexts={registrationTexts} changeLanguage={changeLanguage} registrationData={registrationData} setRegistrationData={updateRegistrationData} setLandingPage={setLandingPage}/>
      case 3:
        //informed consent
        return <RegistrationStep3 registrationTexts={registrationTexts} changeLanguage={changeLanguage} registrationData={registrationData} setRegistrationData={updateRegistrationData} />
      case '3a':
        //vragenlijsten tbv RCT
        return <RegistrationStep3a registrationTexts={registrationTexts} changeLanguage={changeLanguage} registrationData={registrationData} setRegistrationData={updateRegistrationData} />
      case 4:
        //keuze van een coach
        return <RegistrationStep4 registrationTexts={registrationTexts} changeLanguage={changeLanguage} registrationData={registrationData} setRegistrationData={updateRegistrationData} />
      case 5:
        //NAW gegevens + def registratie
        return <RegistrationStep5  registrationTexts={registrationTexts} registrationData={registrationData} setRegistrationData={updateRegistrationData} />
      default:
        return <></>
    }
  }

  return (
    <div className="registrationContainer">

        <Step step={registrationData.step} language={registrationData.language_id}/>

    </div>
  )
}

export default Registration;
