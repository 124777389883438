import { find } from "domutils";
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import apiCall from '../../api';
import t from "../../translate";

const Scores = () => {

   const translateTypes = {
      "PHQ-9": "phq9",
      "PSS-10": "pss10",
      "IPS": "ips",
      "MHQoL": "mhqol",
      "GAD-7": "gad7",
      "ISI": "isi",
      "RSES": "rses",
      "MHCSF": "mhcsf",
      "UCLA-LS": "calc_ucla_loneliness_score",
      "FMPS-CM": "fmpscm"
   }

   const languages = {
      1: 'nl',
      2: 'eng'
    }
   
   const auth = useSelector(state => state.auth);
   const intervention = useSelector(state => state.intervention);
   const activeLanguage = useSelector(state => state.activeLanguage);

   const [registrationTexts, setRegistrationTexts] = useState(false)
   const [scoresTextT0, setScoresTextT0] = useState('')
   const [scoresTextT1, setScoresTextT1] = useState('')
   const [introtext, setIntrotext] = useState('')
   const [outrotext, setOutrotext] = useState('')

   function insertScores(translateTypes, text, scores) {
      for (const [key, value] of Object.entries(translateTypes)) {
         if (typeof scores[value] !== 'undefined') {
            text = text.replace('[' + key + ']', scores[value])
         } else {
            text = text.replace('[' + key + ']', '')
         }
       }
      return text;
   }   

   useEffect(() => {
      //registratieteksten ophalen
      if (auth.token.length > 0) {
         apiCall({
           action: "get_registration_texts",
           data: {
             token: auth.token
           }
         }).then(resp => {
           setRegistrationTexts(resp.content);
         })
      }
   }, [auth.token])

   useEffect(() => {

      if (registrationTexts && intervention.id > 0 && Object.keys(auth).length > 0 && Object.keys(auth.t0_scores).length > 0) {

         let fbText = ''
         let fbArray = registrationTexts.panel6.interventions.find(item => item.id == intervention.id)

         setIntrotext(fbArray.introscores[languages[activeLanguage]])
         setOutrotext(fbArray.outroscores[languages[activeLanguage]])

         let foundTypes = [];

         fbArray.T0T1scores.forEach(element => {

            let type = translateTypes[element.type]
            if (typeof auth.t0_scores[type] !== 'undefined') {
               if (foundTypes.indexOf(type) === -1 && ((element.operator === '<=' && parseInt(auth.t0_scores[type]) <= parseInt(element.operand)) || (element.operator === '>' && parseInt(auth.t0_scores[type]) > parseInt(element.operand)))) {
                  fbText = fbText + '<p>' + element.description[languages[activeLanguage]] + '</p>'
                  foundTypes.push(type)
               }
            }
         })

         setScoresTextT0(insertScores(translateTypes, fbText, auth.t0_scores))

      } 

      if (registrationTexts && intervention.id > 0 && Object.keys(auth).length > 0 && Object.keys(auth.t1_scores).length > 0) {

         let fbText = ''
         let fbArray = registrationTexts.panel6.interventions.find(item => item.id == intervention.id)


         let foundTypes = [];
         fbArray.T0T1scores.forEach(element => {

            let type = translateTypes[element.type]
            if (typeof auth.t1_scores[type] !== 'undefined') {
               if (foundTypes.indexOf(type) === -1 && ((element.operator === '<=' && parseInt(auth.t1_scores[type]) <= parseInt(element.operand)) || (element.operator === '>' && parseInt(auth.t1_scores[type]) > parseInt(element.operand)))) {
                  fbText = fbText + '<p>' + element.description[languages[activeLanguage]] + '</p>'
                  foundTypes.push(type)
               }
            }
         })

         setScoresTextT1(insertScores(translateTypes, fbText, auth.t1_scores))

      } 

   }, [registrationTexts, intervention, auth])

   return (
      <div className="content">

         <h1>{t("Jouw scores")}</h1>

         <p dangerouslySetInnerHTML={{__html: introtext}} />

         <strong>{t("Aan het begin van het programma")}</strong><i>({auth.t0_scores.date})</i><br /><br />
         <div dangerouslySetInnerHTML={{__html: scoresTextT0}} />

         {
         typeof auth.t1_scores.date !== "undefined" ?
            <>
               <br />
               <strong>{t("Aan het einde van het programma")}</strong><i>({auth.t1_scores.date})</i><br /><br />
               <div dangerouslySetInnerHTML={{__html: scoresTextT1}} />
            </>
            :
            <>
               <br />
               <strong>{t("Na het volgen van het programma")}</strong>
               <p>{t("Wil je weten of je vooruit bent gegaan? Houd dan je inbox in de gaten. Enkele weken na de start van het programma krijg je dezelfde vragenlijsten via de mail. Je scores zullen hier verschijnen.")}</p>
            </>
         }

         <br />
         <p dangerouslySetInnerHTML={{__html: outrotext}} />

      </div>
   )
}

export default Scores