import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import apiCall from '../../api';
import t from "../../translate";
import { setActivePart, setShowLeftMenu, setActiveSubLesson, setAnswersLessons } from "../../../actions";

const BaselineFeedback = ({ showSuicideMessage }) => {

   const dispatch = useDispatch()
   const history = useHistory()

   const translateTypes = {
      "PHQ-9": "phq9",
      "PSS-10": "pss10",
      "IPS": "ips",
      "MHQoL": "mhqol",
      "GAD-7": "gad7",
      "ISI": "isi",
      "RSES": "rses",
      "MHCSF": "mhcsf",
      "UCLA-LS": "calc_ucla_loneliness_score",
      "FMPS-CM": "fmpscm"
   }

   const languages = {
      1: 'nl',
      2: 'eng'
    }
   
   const auth = useSelector(state => state.auth);
   const intervention = useSelector(state => state.intervention);
   const activeLanguage = useSelector(state => state.activeLanguage);

   const [registrationTexts, setRegistrationTexts] = useState(false)
   const [suicidemessage, setSuicidemessage] = useState('')
   const [feedbackText, setFeedbackText] = useState('')
   const [introtext, setIntrotext] = useState('')
   const [outrotext, setOutrotext] = useState('')
   

   useEffect(() => {
      //registratieteksten ophalen
      if (auth.token.length > 0) {
         apiCall({
           action: "get_registration_texts",
           data: {
             token: auth.token
           }
         }).then(resp => {
           setRegistrationTexts(resp.content);
         })
      }
      //course answers opnieuw ophalen, zijn nl overschreven door T0
      apiCall({
         action: "get_course_answers",
         token: auth.token,
         data: {
           id: intervention.id
         }
       }).then(resp => {
         dispatch(
           setAnswersLessons(
             intervention.id,
             resp.answers
             )
         )
      })
   }, [auth.token])

   function insertScores(translateTypes, text, scores) {
      for (const [key, value] of Object.entries(translateTypes)) {
         if (typeof scores[value] !== 'undefined') {
            text = text.replace('[' + key + ']', scores[value])
         } else {
            text = text.replace('[' + key + ']', '')
         }
       }
      return text;
   }

   useEffect(() => {

      if (registrationTexts && intervention.id > 0 && Object.keys(auth).length > 0 && Object.keys(auth.t0_scores).length > 0) {

         let fbText = ''
         let fbArray = registrationTexts.panel6.interventions.find(item => item.id == intervention.id)

         setIntrotext(fbArray.introfeedback[languages[activeLanguage]])
         setOutrotext(fbArray.outrofeedback[languages[activeLanguage]])

         let foundTypes = [];

         fbArray.T0T1scores.forEach(element => {

            let type = translateTypes[element.type]
            if (typeof auth.t0_scores[type] !== 'undefined') {
               if (foundTypes.indexOf(type) === -1 && ((element.operator === '<=' && parseInt(auth.t0_scores[type]) <= parseInt(element.operand)) || (element.operator === '>' && parseInt(auth.t0_scores[type]) > parseInt(element.operand)))) {
                  fbText = fbText + '<p>' + element.description[languages[activeLanguage]] + '</p>'
                  foundTypes.push(type)
               }
            }
         })

         setFeedbackText(insertScores(translateTypes, fbText, auth.t0_scores))

         setSuicidemessage(registrationTexts.panel6.suicidemessage[languages[activeLanguage]])
      } 

   }, [registrationTexts, intervention, auth.t0_scores])

   const goLessons = () => {
      dispatch(setActivePart('lessons'));
      dispatch(setShowLeftMenu(false));
      dispatch(setActiveSubLesson(0));
      history.push("/course/"+intervention.id+"/lessons");
   }

   return (
      <div className="content">

         <h1>{t("Terugkoppeling")}</h1>

         <p dangerouslySetInnerHTML={{__html: introtext}} />

         {showSuicideMessage ? <div dangerouslySetInnerHTML={{__html: suicidemessage}}></div> : <></>}

         <div dangerouslySetInnerHTML={{__html: feedbackText}} />

         <br />
         <p dangerouslySetInnerHTML={{__html: outrotext}} />

         {/* <button className="btn btn-primary" onClick={() => window.location.href = '/'}>{t("Afronden")}</button> */}

         <button className="btn btn-primary" onClick={() => goLessons()}>{t("Afronden")}</button>

      </div>
   )
}

export default BaselineFeedback
