import React, { useEffect } from "react"
import t from "../translate"

const Panel6 = ({ registrationTexts, setRegistrationTexts, getTextInput, getContentEditable, getEditor, interventions }) => {

   useEffect(() => {
      //bevat array registrationTexts.panel6.interventions alle interventies?
      if (Object.entries(registrationTexts).length > 0 && interventions.length > 0) {
         let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
         let update = false;
         interventions.forEach((intervention, index) => {
            let findIt = registrationTexts.panel6.interventions.findIndex(int => {
               return int.id === intervention.id
            })
            if (findIt === -1) {
               let newIntervention = {
                  id: intervention.id,
                  T0T1scores: []
               }
               registrationTextsTemp.panel6.interventions.push(newIntervention)
               update = true
            } 

         })
         if (update) {
            setRegistrationTexts(registrationTextsTemp)
         }
      }

   }, [registrationTexts, interventions])

   function removeT0T1Score(interventionId, index) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      registrationTextsTemp.panel6.interventions.find(int => {
         return int.id === interventionId
      }).T0T1scores.splice(index, 1)
      setRegistrationTexts(registrationTextsTemp)
   }

   function addTableRow(cell1, cell2, cell3) {
      return (
         <tr><td>{cell1}</td><td>{cell2}</td><td>{cell3}</td></tr>
      )
   }

   const questionnaire_scores = ["", "GAD-7", "IPS", "ISI", "MHQoL", "PHQ-9", "PSS-10", "RSES", "MHCSF", "UCLA-LS", "FMPS-CM"];

   function onChange(e, param, interventionId, index) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      registrationTextsTemp.panel6.interventions.find(int => {
         return int.id === interventionId
      }).T0T1scores[index][param] = e.target.value
      setRegistrationTexts(registrationTextsTemp)
   }

   function addT0T1Score(interventionId) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let newScore = {
         type: '',
         operator: '',
         operand: '',
         description:{
            nl: '',
            eng: '',
         }
      }
      registrationTextsTemp.panel6.interventions.find(int => {
         return int.id === interventionId
      }).T0T1scores.push(newScore)
      setRegistrationTexts(registrationTextsTemp)
   }

   return (
      <div>
         <h2>{t("Score uitslagen T0/T1")}</h2>
         <br />
         <table className="table table-sm table-bordered">
            <tr>
               <td colSpan="5">
               <label>Suiciderisico tekst NL</label>
               {getEditor('panel6_suicidemessage_nl', registrationTexts.panel6.suicidemessage.nl)}
               <br />
               <label>Intro feedbackpage ENG</label>
               {getEditor('panel6_suicidemessage_eng', registrationTexts.panel6.suicidemessage.eng)}
               </td>
            </tr>
         </table>
         {
            interventions.map((intervention, key) => {
               let interv = registrationTexts.panel6.interventions.find(int => {
                  return int.id === intervention.id
               })
               return (
                  <div key={key} style={{minHeight: '100px', borderBottom: '1px solid #000', marginBottom: '30px'}}>
                     <h5 dangerouslySetInnerHTML={{__html: intervention.title}}></h5>
                     <button className="btn btn-primary btn-sm btn-trans float-right" onClick={() => addT0T1Score(interv.id)} style={{marginTop: '-40px'}}>
                        + {t("Toevoegen")}
                     </button>
                     <table className="table table-sm table-bordered">
                        <tr>
                           <td colSpan="5">
                           <label>Intro terugkoppelingpagina NL</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_introfeedback_nl', interv.introfeedback.nl)}
                           <br />
                           <label>Intro feedbackpage ENG</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_introfeedback_eng', interv.introfeedback.eng)}
                           </td>
                        </tr>
                        <tr>
                           <td colSpan="5">
                           <label>Outro terugkoppelingpagina NL</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_outrofeedback_nl', interv.outrofeedback.nl)}
                           <br />
                           <label>Outro feedbackpage ENG</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_outrofeedback_eng', interv.outrofeedback.eng)}
                           </td>
                        </tr>
                        <tr>
                           <td colSpan="5">
                           <label>Intro scorepagina NL</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_introscores_nl', interv.introscores.nl)}
                           <br />
                           <label>Intro scorepage ENG</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_introscores_eng', interv.introscores.eng)}
                           </td>
                        </tr>
                        <tr>
                           <td colSpan="5">
                           <label>Outro scorepagina NL</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_outroscores_nl', interv.outroscores.nl)}
                           <br />
                           <label>Outro scorepage ENG</label>
                           {getEditor('panel6_interventions_'+intervention.id+'_outroscores_eng', interv.outroscores.eng)}
                           </td>
                        </tr>
                     {
                        typeof interv !== "undefined" ?
                           <>
                           {
                              interv.T0T1scores.map((item, key) => {
                                 return(
                                    <tr key={key}>
                                       <td width="80px">
                                          <button className="btn btn-danger btn-sm btn-trans float-right" onClick={() => removeT0T1Score(interv.id, key)}><i className="fa fa-trash"></i></button>
                                       </td>
                                       <td width="80px">
                                          <select className="form-control" id={'panel6_interventions_'+intervention.id+'_T0T1scores_'+key+'_type'} value={item.type} onChange={(e) => onChange(e, 'type', interv.id, key)}>
                                             {
                                                questionnaire_scores.map((qscore, key) => {
                                                   return (
                                                      <option key={key} value={qscore}>{qscore}</option>
                                                   )
                                                })
                                             }
                                           </select>
                                       </td>
                                       <td width="80px">
                                          <select className="form-control" id={'panel6_interventions_'+intervention.id+'_T0T1scores_'+key+'_operator'} value={item.operator} onChange={(e) => onChange(e, 'operator', interv.id, key)}>
                                             <option value=""></option>
                                             <option value="<=">&lt;=</option>
                                             <option value=">">&gt;</option>
                                           </select>
                                       </td>
                                       <td width="80px">
                                           <input type="number" className="form-control" id={'panel6_interventions_'+intervention.id+'_T0T1scores_'+key+'_operand'} value={item.operand} onChange={(e) => onChange(e, 'operand', interv.id, key)} /> 
                                       </td>
                                       <td>
                                          <label>Uitslag NL</label>
                                          {getEditor('panel6_interventions_'+intervention.id+'_T0T1scores_'+key+'_description_nl', item.description.nl)}
                                          <br />
                                          <label>Uitslag ENG</label>
                                          {getEditor('panel6_interventions_'+intervention.id+'_T0T1scores_'+key+'_description_eng', item.description.eng)}
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                           </>
                        : <></>
                     }
                     </table>
                  </div>
               )
            })
          }

      </div>
   )
} 

export default Panel6
